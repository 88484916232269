@font-face {
	font-family: 'pf_agora_slab_probold';
	src: url('/css/frontend/fonts/pfagoraslabpro-bold-webfont.eot');
	src: url('/css/frontend/fonts/pfagoraslabpro-bold-webfont.eot?#iefix') format('embedded-opentype'),
	url('/css/frontend/fonts/pfagoraslabpro-bold-webfont.woff2') format('woff2'),
	url('/css/frontend/fonts/pfagoraslabpro-bold-webfont.woff') format('woff'),
	url('/css/frontend/fonts/pfagoraslabpro-bold-webfont.ttf') format('truetype'),
	url('/css/frontend/fonts/pfagoraslabpro-bold-webfont.svg#pf_agora_slab_probold') format('svg');
	font-weight: normal;
	font-style: normal;

}
@font-face {
	font-family: 'museo_sans_cyrillic500';
	src: url('/css/frontend/fonts/museosanscyrl-500-webfont.eot');
	src: url('/css/frontend/fonts/museosanscyrl-500-webfont.eot?#iefix') format('embedded-opentype'),
	url('/css/frontend/fonts/museosanscyrl-500-webfont.woff2') format('woff2'),
	url('/css/frontend/fonts/museosanscyrl-500-webfont.woff') format('woff'),
	url('/css/frontend/fonts/museosanscyrl-500-webfont.ttf') format('truetype'),
	url('/css/frontend/fonts/museosanscyrl-500-webfont.svg#museo_sans_cyrillic500') format('svg');
	font-weight: normal;
	font-style: normal;

}

@font-face {
    font-family: 'helios';
    src: url('fonts/HeliosCond.eot');
    src: url('fonts/HeliosCond.eot?#iefix') format('embedded-opentype'),
    url('fonts/HeliosCond.woff') format('woff'),
    url('fonts/HeliosCond.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.error-page {
	background-image: url('/css/frontend/img/404-background.png');
	background-position: 50% 90%;
	background-repeat: no-repeat;
	height: 100vh;
	margin: 0;
	padding-bottom: 0 !important;
}
.error-page .error-page-block {
	text-align: center;
	height: 50%;
	margin: auto;
	position: absolute;
	top: 0; left: 0; bottom: 0; right: 0;
}
.error-page h1 {
	font-size: 36px;
	color:#000;
	font-family: 'pf_agora_slab_probold', Helvetica, Arial, sans-serif;
	margin-top: 0;
}
.error-page .error-text-green {
	font-size: 204px;
	line-height: 204px;
	font-family: 'pf_agora_slab_probold', Helvetica, Arial, sans-serif;
	color: #588e12;
	margin: 0;
}
.error-page .error-links {
	font-family: 'museo_sans_cyrillic500', Helvetica, Arial, sans-serif;
	color:#000;
	font-size: 18px;
}
.error-page .error-links a {
	color: #588e12;
}
@media only screen and (max-width: 780px){
	.error-page .error-page-block { padding: 0 20px;}
	.error-page .error-text-green {
		font-size: 160px;
		line-height: 160px;
	}
}
@media only screen and (max-width: 540px){
	.error-page .error-text-green {
		font-size: 120px;
		line-height: 120px;
	}
}